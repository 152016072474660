<template>
  <div class="col-12 g">
    <div class="card">
        <div class="card-body">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-bordered table-sm">
                    <thead>
                      <th>
                        رقم المشروع
                      </th>
                      <th>
                        التاريخ
                      </th>
                    </thead>
                    <tbody>
                       <tr v-for="order in orders" :key="order._id">
                        <td>
                            {{ order.item_id }}
                        </td>
                        <td>
                            {{ order.date }}
                        </td>
                       </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { BOverlay, BModal, VBModal, BFormCheckbox } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BOverlay,
    BModal,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            loading: false,
            user: JSON.parse(localStorage.getItem("user")),
            orders: []
        }
    },
    created(){
        var g = this;
        g.getProjects();
    },
    methods: {
        getProjects(){
            var g = this;
            g.loading = true;
            $.post(api + '/user/projects/noti', {
                jwt: g.user.jwt
            }).then(function(r){
                g.orders = r.response
            })
        }
    }
}
</script>

<style>

</style>